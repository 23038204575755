import { Navigate, createBrowserRouter } from 'react-router-dom';
import { DashboardLayout } from 'src/shared/Layout/DashboardLayout';
import { RouterError } from 'src/shared/components/RouterError/RouterError';

export const router = createBrowserRouter([
  {
    path: '/components',
    lazy: () => import('src/pages/ComponentViews'),
  },
  {
    path: '/entry',
    lazy: () => import('src/pages/EntryPage/EntryPage'),
  },
  {
    path: '/',
    element: <DashboardLayout />,
    hasErrorBoundary: true,
    errorElement: <RouterError />,
    children: [
      {
        index: true,
        element: <Navigate to="/investment-opportunities" />,
      },
      {
        path: '/dashboard',
        lazy: () => import('src/pages/Dashboard'),
      },
      {
        path: '/investment-opportunities',
        lazy: () => import('src/pages/InvestmentOpportunities/InvestmentOpportunitiesPage'),
      },
      {
        path: '/investment-opportunities/:id',
        lazy: () => import('src/pages/InvestmentOpportunity/InvestmentOpportunityPage'),
      },
      {
        path: '/my-investments-basket',
        lazy: () => import('src/pages/MyInvestmentsBasket/MyInvestmentsBasketPage'),
      },
      {
        path: '/wallet',
        lazy: () => import('src/pages/Wallet/WalletPage'),
      },
      {
        path: '/market/initial-supply',
        lazy: () => import('src/pages/InitialSupply/InitialSupplyPage'),
      },
      {
        path: '/market/otc',
        lazy: () => import('src/pages/FastBuyingAndSelling/FastBuyingAndSellingPage'),
      },
      {
        path: '/history',
        lazy: () => import('src/pages/History'),
      },
      {
        path: '/introduce-to-friends',
        lazy: () => import('src/pages/IntroduceToFriends'),
      },
      {
        path: '/support',
        lazy: () => import('src/pages/Support/Support'),
      },
      {
        path: '/support/:id',
        lazy: () => import('src/pages/ChatPage/ChatPage'),
      },
      {
        path: '/settings',
        lazy: () => import('src/pages/Settings'),
      },
      {
        path: '/help',
        lazy: () => import('src/pages/Help'),
      },
      {
        path: '/authentication',
        lazy: () => import('src/pages/AuthenticationPage/Authentication'),
      },
      {
        path: '/notifications/:id',
        lazy: () => import('src/pages/NotificationsPage/NotificationsPage'),
      },
    ],
  },
]);
