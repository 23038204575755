import { t } from '@lingui/macro';
import { Avatar, Button, Chip, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { USER_ROLE } from 'src/core/domain/auth/localStorageKeys';
import { sharedAuthQueryKeygen } from 'src/core/query/sharedKeygen';
import { useMedia } from 'src/shared/hooks';
import { makeStyles } from 'tss-react/mui';
import { ArrowPrev } from '../BidiIcons/ArrowPrev';

export const DesktopUserInfoBox = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const isMd = useMedia('md');
  const { data: status } = useQuery({
    ...sharedAuthQueryKeygen('/api/v1/auth/user/investor/auth/status', {
      params: { header: {} },
    }),
    enabled: true,
  });
  const authenticationStep = status?.data?.account;
  const role = localStorage.getItem(USER_ROLE);
  const { data: personalInfo } = useQuery({
    ...sharedAuthQueryKeygen('/api/v1/auth/user/investor/info/personal', {
      params: { header: {} },
    }),
  });

  const handleRedirectToAuthentication = () => {
    navigate('/authentication');
  };

  const color =
    authenticationStep === 'active'
      ? 'success'
      : authenticationStep === 'rejected'
      ? 'warning'
      : 'secondary';

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color={color}
        className={classes.action}
        startIcon={<ArrowPrev />}
        onClick={handleRedirectToAuthentication}
      >
        {authenticationStep === 'active'
          ? t`Authenticated`
          : authenticationStep === 'pending'
          ? t`Authentication pending`
          : authenticationStep === 'rejected'
          ? t`Authentication rejected`
          : t`Authenticate now`}
      </Button>
      {isMd && (
        <div className={classes.info}>
          <Avatar sx={{ width: 40 }} src={''} />
          <Typography variant="body1" color="black">
            {personalInfo?.data?.first_name}
          </Typography>
          {role && (
            <Chip
              size="small"
              label={role === 'Investor' ? t`Investor` : t`Broker`}
              classes={{ root: classes.chip }}
              variant="outlined"
            />
          )}
        </div>
      )}
    </div>
  );
};
const useStyles = makeStyles()((theme) => ({
  root: {
    height: 48,
    background: theme.palette.neutral.white,
    borderRadius: theme.shape.borderRadius,
    padding: 4,
    display: 'flex',
    position: 'relative',
    boxShadow: '0px 8px 24px 0px rgba(11, 18, 38, 0.04)',
  },
  action: {
    height: '100%',
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.up('md')]: {
      paddingRight: 280,
    },
  },
  info: {
    height: 48,
    zIndex: 2,
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.common.white,
    display: 'flex',
    paddingLeft: 4,
    paddingRight: 4,
    alignItems: 'center',
    gap: 8,
  },
  chip: {
    background: theme.palette.neutral.lightBlue,
    color: theme.palette.secondary.dark,
  },
}));
